import React from "react";
import ReactSelect from "react-select";
import { useBrands } from "services/brands/hooks";
import { useSimulatorContext } from "./SimulatorContext";
import StepBlockBase from "./StepBlockBase";

const StepBlockBrands = () => {
  const { brands } = useBrands();
  const { selectBrand, selectedBrand } = useSimulatorContext();

  return (
    <StepBlockBase title="Quelle est sa marque ?">
      <div className="text-left">
        <ReactSelect
          placeholder="Rechercher une marque..."
          className="font-cardo"
          options={brands?.map((b) => ({
            label: b.name,
            value: b.id,
          }))}
          onChange={(v) => {
            if (!v?.value) return;
            const brand = brands?.find((b) => b.id === v.value);
            if (brand) {
              selectBrand(brand);
            }
          }}
          value={
            selectedBrand
              ? {
                  label: selectedBrand.name,
                  value: selectedBrand.id,
                }
              : null
          }
        />
      </div>
    </StepBlockBase>
  );
};

export default StepBlockBrands;
