import { motion } from "framer-motion";
import React, { useEffect, useMemo, useRef } from "react";
import { formatPrice } from "services/commons/helpers";
import { useSimulatorContext } from "./SimulatorContext";

const FinalPriceBlock: React.FC = () => {
  const {
    price: { data, error, isValidating },
    resetSimulatorState,
  } = useSimulatorContext();

  const ref = useRef<HTMLDivElement>(null);
  const loading = !data && !error;

  const renderedContent = useMemo(() => {
    if (loading) {
      return <div>Estimation en cours ...</div>;
    }

    if (error != null) {
      return (
        <div className="bg-bb-light-gray font-cardo text-bb-dark-gray text-base p-10">
          Le prix de votre article n'est pas encore déterminé mais pas
          d'inquiétude, si celui-ci fait partie de notre liste de marque, vous
          pouvez tout de même l'insérer dans votre colis. ;)
        </div>
      );
    }

    return (
      <div className="bg-bb-light-pink font-gotham text-white p-6">
        <div className="text-lg uppercase">Mon article est estimé entre</div>
        <div className="text-bb-yellow text-5xl mt-2">
          {formatPrice(data!.lowest, true)} et{" "}
          {formatPrice(data!.highest, true)}
        </div>
      </div>
    );
  }, [data, error, loading]);

  useEffect(() => {
    if (!ref) return;

    ref.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  }, [ref, loading]);

  if (!data && !error && !isValidating) return null;

  return (
    <motion.div
      ref={ref}
      initial={{ x: -100 }}
      animate={{ x: 0 }}
      transition={{ type: "spring", duration: 0.8 }}
      className="pb-8"
    >
      {renderedContent}
      <button
        className="bg-bb-light-pink text-white text-xl font-cardo font-bold px-6 py-4 mt-8"
        onClick={() => resetSimulatorState()}
      >
        Estimer un nouvel article
      </button>
    </motion.div>
  );
};

export default FinalPriceBlock;
