import Logo from "assets/images/bb-logo.svg";
import { SimulatorContextProvider } from "./SimulatorContext";
import SimulatorSteps from "./SimulatorSteps";
import Cart from "./Cart";
import { useHealth } from "services/health/hooks";
import { motion } from "framer-motion";
import ReactGA from "react-ga4";
import "./simulator.css";

const SimulatorComponent = () => {
  const { isLoading } = useHealth();

  if (isLoading) {
    return (
      <div className="flex flex-col h-screen items-center justify-center">
        <img src={Logo} alt="logo" className="block h-40 md:h-52" />
        <div className="font-cardo text-bb-dark-gray text-xl">
          Chargement du simulateur ...
        </div>
      </div>
    );
  }

  return (
    <motion.div
      className="flex flex-col max-w-5xl m-auto items-center justify-center pb-10 md:pb-0 px-7 md:px-0"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ type: "spring", duration: 1 }}
    >
      <a
        href="https://www.bybambou.com/"
        target="_blank"
        rel="noreferrer"
        className="outline-none"
      >
        <img src={Logo} alt="logo" className="h-40 md:h-52" />
      </a>
      <div
        className="bg-bb-light-pink w-full md:w-2/3"
        style={{ height: "1px" }}
      />

      <div className="flex flex-col md:flex-row items-center md:items-stretch w-full justify-center mt-8 md:mt-10">
        <motion.div
          className="text-center grow w-full md:w-2/3 px-0 md:px-8 lg:px-14 md:border-r border-bb-light-pink pt-4"
          layout
        >
          <h1 className="font-gotham uppercase text-2xl md:text-3xl text-bb-green">
            Estimez le prix de reprise de vos articles
          </h1>
          <p className="font-cardo font-bold text-sm mt-6 text-bb-dark-gray">
            Vous souhaitez vendre les vêtements de vos kids & vous aimeriez
            savoir à combien nous les rachetons ? Vous êtes au bon endroit ;)
          </p>
          <p className="font-cardo font-bold text-sm mt-3 text-bb-dark-gray">
            NB : Nos équipes évaluent vos pièces le plus justement possible. Les
            prix de rachat sont fermes et définitifs, en nous envoyant vos
            articles, vous vous engagez à les accepter.
          </p>
          <div className="mt-8" />
          <SimulatorSteps />
        </motion.div>

        <div className="text-center w-full md:w-1/3 mt-4 md:mt-0 px-0 md:px-6 lg:px-8 sticky self-start top-0 pt-4">
          <div className="font-gotham uppercase text-xl text-bb-green mb-4">
            Total du prix de rachat
          </div>
          <Cart />
        </div>
      </div>
    </motion.div>
  );
};

const Simulator = () => {
  ReactGA.send("pageview");
  return (
    <SimulatorContextProvider>
      <SimulatorComponent />
    </SimulatorContextProvider>
  );
};

export default Simulator;
