import last from "lodash/last";
import React, { useCallback, useEffect, useMemo, useReducer } from "react";
import { useState } from "react";
import { Brand } from "services/brands/types";
import { Category } from "services/categories/types";
import { ItemCondition } from "services/commons/types";
import { getPrice } from "services/prices/api";
import { Price } from "services/prices/types";
import { Size } from "services/sizes/types";
import useSWR, { SWRResponse } from "swr";

export type CartItem = {
  brand: Brand;
  categories: Category[];
  size: Size;
  condition: ItemCondition;
  price: Price;
};

type SimulatorContextValues = {
  cart: CartItem[];
  selectedBrand: Brand | null;
  selectedCategories: Category[];
  selectedSize: Size | null;
  selectedItemCondition: ItemCondition | null;
  addCurrentToCart(): void;
  addItemToCart(item: CartItem): void;
  deleteCartItem(index: number): void;
  selectBrand(brand: Brand): void;
  insertCategory: (category: Category, index: number) => void;
  selectSize(size: Size): void;
  selectItemCondition(condition: ItemCondition): void;
  resetSimulatorState(): void;
  price: SWRResponse<Price, any>;
};

const SimulatorContext = React.createContext<SimulatorContextValues>(
  {} as SimulatorContextValues
);

type SimulatorState = {
  selectedBrand: Brand | null;
  selectedCategories: Category[];
  selectedSize: Size | null;
  selectedItemCondition: ItemCondition | null;
};

type SimulatorAction =
  | { type: "SELECT_BRAND"; brand: Brand }
  | { type: "INSERT_CATEGORY"; category: Category; index: number }
  | { type: "SELECT_SIZE"; size: Size }
  | { type: "SELECT_ITEM_CONDITION"; itemCondition: ItemCondition }
  | { type: "RESET_STATE" };

const initialSimulatorState: SimulatorState = {
  selectedBrand: null,
  selectedCategories: [],
  selectedSize: null,
  selectedItemCondition: null,
};

function reducer(
  state: SimulatorState,
  action: SimulatorAction
): SimulatorState {
  switch (action.type) {
    case "SELECT_BRAND":
      return {
        selectedBrand: action.brand,
        selectedCategories: [],
        selectedSize: null,
        selectedItemCondition: null,
      };
    case "INSERT_CATEGORY":
      return {
        ...state,
        selectedCategories: [
          ...state.selectedCategories.slice(0, action.index),
          action.category,
        ],
        selectedSize: null,
        selectedItemCondition: null,
      };
    case "SELECT_SIZE":
      return {
        ...state,
        selectedSize: action.size,
        selectedItemCondition: null,
      };
    case "SELECT_ITEM_CONDITION":
      return {
        ...state,
        selectedItemCondition: action.itemCondition,
      };
    case "RESET_STATE":
      return initialSimulatorState;
    default:
      return state;
  }
}

export const SimulatorContextProvider: React.FC = ({ children }) => {
  const [cart, setCart] = useState<CartItem[]>([]);
  const [
    { selectedBrand, selectedCategories, selectedSize, selectedItemCondition },
    dispatch,
  ] = useReducer(reducer, initialSimulatorState);

  const price = useSWR<Price>(
    () => {
      const category = last(selectedCategories);
      if (
        selectedBrand === null ||
        !category ||
        category.hasChildren === true ||
        selectedSize === null ||
        selectedItemCondition === null
      ) {
        return null;
      }

      return `GET_PRICE_BRD_${selectedBrand.id}_CAT_${category.id}_SIZ_${selectedSize.id}_CND_${selectedItemCondition}`;
    },
    () =>
      getPrice({
        brandId: selectedBrand!.id,
        categoryId: last(selectedCategories)!.id,
        sizeId: selectedSize!.id,
        condition: selectedItemCondition!,
      }),
    { revalidateOnFocus: false }
  );

  const addItemToCart = useCallback(
    (item: CartItem) => setCart((cart) => [...cart, item]),
    []
  );

  const currentCartItem = useMemo<CartItem | null>(() => {
    const category = last(selectedCategories);
    if (
      selectedBrand === null ||
      !category ||
      selectedSize === null ||
      selectedItemCondition === null ||
      !price.data
    ) {
      return null;
    }

    return {
      brand: selectedBrand,
      categories: selectedCategories,
      size: selectedSize,
      condition: selectedItemCondition,
      price: price.data,
    };
  }, [
    price.data,
    selectedBrand,
    selectedCategories,
    selectedSize,
    selectedItemCondition,
  ]);

  const addCurrentToCart = useCallback(() => {
    if (currentCartItem) {
      addItemToCart(currentCartItem);
    }
  }, [addItemToCart, currentCartItem]);

  const deleteCartItem = useCallback(
    (index: number) => {
      setCart(cart.filter((item, idx) => idx !== index));
    },
    [cart]
  );

  useEffect(() => {
    const p = price.data;
    if (!p) return;
    addCurrentToCart();
  }, [price.data, addCurrentToCart]);

  const selectBrand = useCallback(
    (brand: Brand) => dispatch({ type: "SELECT_BRAND", brand }),
    [dispatch]
  );

  const insertCategory = useCallback(
    (category: Category, index: number) =>
      dispatch({ type: "INSERT_CATEGORY", category, index }),
    [dispatch]
  );

  const selectSize = useCallback(
    (size: Size) => dispatch({ type: "SELECT_SIZE", size }),
    [dispatch]
  );

  const selectItemCondition = useCallback(
    (itemCondition: ItemCondition) =>
      dispatch({ type: "SELECT_ITEM_CONDITION", itemCondition }),
    [dispatch]
  );

  const resetSimulatorState = useCallback(
    () => dispatch({ type: "RESET_STATE" }),
    [dispatch]
  );

  return (
    <SimulatorContext.Provider
      value={{
        cart,
        selectedBrand,
        selectedCategories,
        selectedSize,
        selectedItemCondition,
        addItemToCart,
        addCurrentToCart,
        deleteCartItem,
        selectBrand,
        insertCategory,
        selectSize,
        selectItemCondition,
        resetSimulatorState,
        price,
      }}
    >
      {children}
    </SimulatorContext.Provider>
  );
};

export const useSimulatorContext = () => React.useContext(SimulatorContext);

export default SimulatorContext;
