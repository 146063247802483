import useSWR from "swr";
import { getSizes } from "./api";
import { Size } from "./types";

export const useSizes = () => {
  const { data, error } = useSWR<Size[]>(`GET_SIZES`, () => getSizes(), {
    revalidateOnFocus: false,
  });

  return {
    sizes: data,
    isLoading: !error && !data,
    error,
  };
};
