import React from "react";
import { useSimulatorContext } from "./SimulatorContext";
import StepBlockCategories from "./StepBlockCategories";
import last from "lodash/last";
import StepBlockBrands from "./StepBlockBrands";
import StepBlockSizes from "./StepBlockSizes";
import StepBlockItemCondition from "./StepBlockItemCondition";
import FinalPriceBlock from "./FinalPriceBlock";

const SimulatorSteps = () => {
  const { selectedCategories, selectedBrand, selectedSize } =
    useSimulatorContext();
  const hasFinishedCategoriesSelection =
    last(selectedCategories)?.hasChildren === false;

  return (
    <>
      <StepBlockBrands />
      {selectedBrand && <StepBlockCategories index={0} />}
      {selectedBrand &&
        selectedCategories
          .filter((c) => c.hasChildren)
          .map((c, idx) => (
            <StepBlockCategories key={idx} index={idx + 1} parentId={c.id} />
          ))}
      {hasFinishedCategoriesSelection && <StepBlockSizes />}
      {hasFinishedCategoriesSelection && selectedSize && (
        <StepBlockItemCondition />
      )}

      <FinalPriceBlock />
    </>
  );
};

export default SimulatorSteps;
