import useSWR from "swr";
import { getHealth } from "./api";

export const useHealth = () => {
  const { data, error, isValidating } = useSWR<void>(`GET_HEALTH`, () =>
    getHealth()
  );

  return {
    alive: !error && !isValidating,
    isLoading: !data && !error && isValidating,
    error,
  };
};
