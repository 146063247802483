import { ItemCondition } from "./types";

export const itemConditionToString = (condition: ItemCondition): string => {
  switch (condition) {
    case ItemCondition.Good:
      return "Bon état";
    case ItemCondition.VeryGood:
      return "Très bon état";
    case ItemCondition.BrandNew:
      return "Neuf";
  }
};

export const formatPrice = (price: number, round: boolean = false): string => {
  return new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "EUR",
    ...(round && { minimumFractionDigits: 0, maximumFractionDigits: 1 }),
  }).format(price / 100);
};
