import React from "react";
import classnames from "classnames";
import StepBlockBase from "./StepBlockBase";

interface StepBlockChoicesProps<
  ChoiceType extends any,
  KeyField extends keyof ChoiceType
> {
  title: string;
  choices: ChoiceType[];
  loading?: boolean;
  renderUniqueId?: string | number;
  keyField: KeyField;
  selectedChoiceId: ChoiceType[KeyField] | null;
  onSelect?(choiceId: ChoiceType[KeyField], choice: ChoiceType): void;
  render: (choice: ChoiceType) => React.ReactNode;
}

function StepBlockChoices<
  ChoiceType extends any,
  KeyField extends keyof ChoiceType = keyof ChoiceType
>({
  title,
  choices,
  render,
  onSelect,
  loading,
  selectedChoiceId,
  keyField,
}: React.PropsWithChildren<StepBlockChoicesProps<ChoiceType, KeyField>>) {
  return (
    <StepBlockBase title={title} loading={loading}>
      <div className="step-block-choices-content inline-flex flex-wrap items-center justify-center min-w-0 w-full">
        {[
          choices.map((v, idx) => {
            return (
              <button
                key={idx}
                className={classnames(
                  "step-block-choices-item",
                  {
                    selected:
                      selectedChoiceId !== null &&
                      selectedChoiceId === v[keyField],
                  },
                  "overflow-hidden min-w-0 border border-gray-300 bg-white text-bb-dark-gray px-3 py-0.5 font-cardo cursor-pointer"
                )}
                onClick={() => {
                  const id = v[keyField];
                  onSelect?.(id, v);
                }}
              >
                {render(v)}
              </button>
            );
          }),
        ]}
      </div>
    </StepBlockBase>
  );
}

export default StepBlockChoices;
