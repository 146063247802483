import useSWR from "swr";
import { getCategories } from "./api";
import { Category } from "./types";

export const useCategories = (parentId?: number | null) => {
  const { data, error } = useSWR<Category[]>(
    `GET_CATEGORIES_PARENT_ID_${parentId ?? "NULL"}`,
    () => getCategories(parentId),
    { revalidateOnFocus: false }
  );

  return {
    categories: data,
    isLoading: !error && !data,
    error,
  };
};
