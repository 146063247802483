import React from "react";
import { formatPrice, itemConditionToString } from "services/commons/helpers";
import {
  CartItem as CartItemType,
  useSimulatorContext,
} from "./SimulatorContext";

const Cart = () => {
  const { cart, deleteCartItem } = useSimulatorContext();
  return (
    <div className="overflow-hidden">
      <div>
        {cart.map((item, index) => (
          <CartItem
            key={index}
            item={item}
            onDeleteClick={() => deleteCartItem(index)}
          />
        ))}
      </div>
      <div className="border-t border-t-bb-green text-bb-dark-gray pt-5 flex flex-row justify-between">
        <div>
          <div className="font-gotham uppercase text-2xl">Total</div>{" "}
          <div className="font-cardo text-sm">(en moyenne)</div>
        </div>
        <div className="font-gotham text-2xl">
          {formatPrice(
            cart.reduce((v, current) => v + current.price.average, 0),
            true
          )}
        </div>
      </div>
    </div>
  );
};

interface CartItemProps {
  item: CartItemType;
  onDeleteClick(): void;
}

const CartItem: React.FC<CartItemProps> = ({ item, onDeleteClick }) => {
  return (
    <div className="font-cardo text-sm text-bb-dark-gray text-left mb-6 flex flex-row items-start">
      <button
        type="button"
        className="px-1 py-0 font-bold rounded font-sans"
        style={{ background: "#BFD9D0" }}
        onClick={onDeleteClick}
      >
        X
      </button>
      <div className="ml-2">
        <div>
          {item.categories[1]?.name ?? item.categories[0]?.name},{" "}
          {item.brand.name}, {item.size.name}
        </div>
        <div>{itemConditionToString(item.condition)}</div>
        <div className="font-bold mt-1">
          {formatPrice(item.price.average, true)} en moyenne
        </div>
      </div>
    </div>
  );
};

export default Cart;
