import React from "react";
import { useSizes } from "services/sizes/hooks";
import { Size } from "services/sizes/types";
import { useSimulatorContext } from "./SimulatorContext";
import StepBlockChoices from "./StepBlockChoices";

interface StepBlockSizesProps {}

const StepBlockSizes: React.FC<StepBlockSizesProps> = () => {
  const { selectSize, selectedSize } = useSimulatorContext();
  const { sizes, isLoading } = useSizes();

  return (
    <StepBlockChoices<Size>
      title="Quelle est sa tranche d'âge ?"
      keyField="id"
      choices={sizes ?? []}
      selectedChoiceId={selectedSize?.id ?? null}
      loading={isLoading}
      render={(size) => size.name}
      onSelect={(_id, size) => selectSize(size)}
    />
  );
};

export default StepBlockSizes;
