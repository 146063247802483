import React from "react";
import { itemConditionToString } from "services/commons/helpers";
import { ItemCondition } from "services/commons/types";
import { useSimulatorContext } from "./SimulatorContext";
import StepBlockChoices from "./StepBlockChoices";

type ItemConditionChoice = {
  conditionId: ItemCondition;
};

interface StepBlockItemConditionProps {}

const StepBlockItemCondition: React.FC<StepBlockItemConditionProps> = () => {
  const { selectItemCondition, selectedItemCondition } = useSimulatorContext();
  const choices: ItemConditionChoice[] = [
    ItemCondition.Good,
    ItemCondition.VeryGood,
    ItemCondition.BrandNew,
  ].map((conditionId) => ({ conditionId }));

  return (
    <StepBlockChoices<ItemConditionChoice>
      title="Quel est son état ?"
      keyField="conditionId"
      selectedChoiceId={selectedItemCondition ?? null}
      choices={choices}
      render={({ conditionId }) => itemConditionToString(conditionId)}
      onSelect={(condition) => {
        selectItemCondition(condition);
      }}
    />
  );
};

export default StepBlockItemCondition;
