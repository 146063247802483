import useSWR from "swr";
import { getBrands } from "./api";
import { Brand } from "./types";

export const useBrands = (search?: string | null) => {
  const { data, error } = useSWR<Brand[]>(`GET_BRANDS`, () => getBrands(), {
    revalidateOnFocus: false,
  });

  return {
    brands: data,
    isLoading: !error && !data,
    error,
  };
};
