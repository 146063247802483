import React from "react";
import { SWRConfig } from "swr";
import Simulator from "./views/Simulator";

function App() {
  return (
    <SWRConfig value={{ revalidateOnFocus: false, errorRetryCount: 0 }}>
      <Simulator />
    </SWRConfig>
  );
}

export default App;
