import React from "react";
import { useCategories } from "services/categories/hooks";
import { Category } from "services/categories/types";
import { useSimulatorContext } from "./SimulatorContext";
import StepBlockChoices from "./StepBlockChoices";

const categoriesStepsTitles = [
  "Quelle est la catégorie de votre article ?",
  "Plus précisement ?",
  "En détails ?",
];

interface StepBlockCategoriesProps {
  index: number;
  parentId?: number | null;
}

const StepBlockCategories: React.FC<StepBlockCategoriesProps> = ({
  index,
  parentId,
}) => {
  const { insertCategory, selectedCategories } = useSimulatorContext();
  const { categories, isLoading } = useCategories(parentId);

  return (
    <StepBlockChoices<Category>
      title={categoriesStepsTitles[index] ?? ""}
      keyField="id"
      choices={categories ?? []}
      loading={isLoading}
      render={(category) => category.name}
      selectedChoiceId={selectedCategories?.[index]?.id ?? null}
      onSelect={(_id, category) => {
        insertCategory(category, index);
      }}
    />
  );
};

export default StepBlockCategories;
