import React, { useEffect, useRef } from "react";
import { motion } from "framer-motion";

interface StepBlockBaseProps {
  title: string;
  loading?: boolean;
}

const StepBlockBase: React.FC<StepBlockBaseProps> = ({
  title,
  loading = false,
  children,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref?.current) return;

    window.scrollTo({ top: ref.current.offsetTop, behavior: "smooth" });
  }, [ref, loading]);

  return (
    <motion.div
      ref={ref}
      className="pb-8"
      initial={{ x: -100 }}
      animate={{ x: 0 }}
      transition={{ type: "spring", duration: 0.8 }}
      layout
    >
      <motion.div
        className="bg-bb-light-gray py-5 px-3 mb-6 bb-sim-step"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ type: "spring", duration: 0.8 }}
      >
        {loading ? (
          <div className="font-cardo text-bb-dark-gray">
            Un instant s'il vous plait :)
          </div>
        ) : (
          <>
            <h2 className="font-gotham uppercase text-bb-dark-gray">{title}</h2>
            <div className="w-full mt-4">{children}</div>
          </>
        )}
      </motion.div>
    </motion.div>
  );
};

export default StepBlockBase;
